$primary: #285f9f;
$secondary : rgba(0, 94, 182, 0.301);
$nav-pills-link-active-bg: rgba(0, 0, 0, 0.05);
$nav-pills-link-active-color: #285f9f;
// $nav-pills-link-color: #BBC5D4;
$nav-link-hover-color: #1c1c1c;
$nav-link-color: #1c1c1c;

$modal-content-color:               null;
$modal-content-bg:white;
$modal-content-border-color:  #285f9f;
$modal-content-border-radius:none;
// $modal-backdrop-bg:                 rgba(27, 25, 25, 0.568);
$modal-backdrop-bg-opacity:0.5;
$modal-backdrop-bg-blur: 15px;


@import "~bootstrap/scss/bootstrap";
@import "~react-toastify/dist/ReactToastify.css";


::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #bbc5d5 !important;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #bbc5d5 !important;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #bbc5d5 !important;
}

/* For WebKit-based browsers */

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-thumb {
  background-color: #6e96c5;

  border-radius: 3px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #285f9f;
}

/* For Firefox-based browsers */

// scrollbar-color: rgba (0, 0, 0, 0.3) transparent;
// scrollbar-width: thin;
// .auth-bt-bg{
//   background: url(./assets/images/auth_bottom_bg.png);
//   background-position: left bottom;
//   background-repeat: no-repeat;
//   background-size: 963px;
// }

// .form-floating label{
//   color: #BBC5D5;
//   font-size: 0.75rem;
// }

// .form-control, .form-select{
//   background-color: #BBC5D521;
//   border-radius: 14px;
//   border: unset;
// }

// .form-control-lg{
//   padding: 1.3rem 1.625rem;
// }

// .btn-lg{
//   padding: 1rem 1.625rem;
// }
.main {
  overflow-x: hidden;
}

.auth-bg {
  background-image: url("./assets/images/authbg.png");
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
}

.auth-field-class .form-group {
  position: relative;
  width: 500px;
  margin-bottom: 1.5rem;

  .form-label {
    position: absolute;

    top: 0.5rem;

    left: 0.75rem;

    font-size: 15px;
    // margin-bottom: 2%;

    color: #6c757d;

    transition: all 0.2s ease-out;

    pointer-events: none;
  }

  // .form-control :focus + .form-label{
  //   top: -1rem;
  //   left: 0;
  //   font-size: 15px;
  //   color: #007bff;
  // }
  #inputField {
    padding-top: 8%;
  }
}
.main-page {
  background-image: url(./assets/images/bg-main.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100vh;
}
.sidebar-parent {
  position: relative;

  // .sidebar-child {
  //   // position: absolute;
  //   bottom: -5px;

    .card-bg{
      background-image: url(./assets/images/sidebar-child-bg.png);
      background-size: cover;
      background-repeat: no-repeat;
      border-top-right-radius: 50px;
      border-top-left-radius: 50px;
      width: 100%;
    }
  
  // }
  .side-nav {
    margin-top: 15%;
  }
}

  .dashboard-card-bg {
    background-color: #eceaea5b;

    .dashboard-card-icon-bg {
      background-color: #f3f9ff;
    }
  }

  .main-card-bg {
    background-color: #eceaea5b;

    .dashboard-card-icon-bg {
      background-color: #f3f9ff;
    }
  }


.customer-table-card {
  height: 520px;
  // overflow: auto;
}

table.dashboard-table thead > tr > th {
  padding: 20px 0px;
  // height:100%;
  .table-body {
    // padding:5px 10px !important;
    // overflow-y: scroll;
  }
}

.policy-table-card {
  height: 550px;
  overflow-y: scroll;
  position:relative;
  // overflow-x:hidden;
  // overflow: auto;
  
  .sticky-header {
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: #fff;
  }
  .table.policy-table thead > tr > th {
    padding: 20px 0px;
    
  }
  .table.policy-table tbody > tr > td {
    padding: 12px 0px;
   
  }

  .dropdown-menu {
    position: absolute;
    right: 0;
    left: auto;
    top: 100%;
    margin-top: 0.5rem;
  }
  

}

.policy-card {
  background-image: url(./assets/images/policysection.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position:center;
  width:100%;
  height: 280px;
  border: none;

  .policy-count {
    font-size: 80px;
    margin-top: 2%;
    margin-bottom: 0;;
  }

  // .policy-card-btn {
  //   margin-top: 2%;
  // }
}

// .check{
//   width:40px;
//   padding-left:4%;
//   background-color: #285F9F;
// }

.btn-link {
  color: #354052;
  text-decoration: none;
}

.btn-primary,
.btn-primary:focus,
.btn-primary:hover,
.btn-primary:active {
  color: #fff;
}

.side-bar  .nav-pills .nav-link.active,
.side-bar  .nav-pills .show > .nav-link {
  border-left: 5px solid #285f9f;
  // border-image: linear-gradient(
  //   to bottom,
  //   rgba(108, 219, 141, 0) 25%,
  //   #32297d 25%,
  //   #285F9F 75%,
  //   rgba(108, 219, 141, 0) 75%
  // );
  border-image-slice: 1;
}

.side-bar .nav-pills .nav-item {
  margin-bottom: 5px;
}

.side-bar  .nav-pills .nav-link {
  border-radius: unset;
  padding: 15px 0;
  font-weight: 600;
  color: #929aa5;
}

.side-bar  .nav-pills .nav-item > .nav-link {
  padding-left: 1.7rem;
  font-size: 0.875em;
}

.side-bar  .nav-pills .nav-item > .nav-link.active {
  padding-left: 1.38rem;
}

.pc-b-3 {
  padding-top: 1.1875rem;
  padding-bottom: 1.4375rem;
}

.pc-2 {
  padding-left: 20px;
  padding-right: 50px;
}

.p-c-a {
  padding: 2rem 1.8rem;
}

.pa-c-a {
  padding: 0rem 9.438rem;
}

@media (max-width: 768px) {
  .pa-c-a {
    padding: $spacer * 3;
  }
}

.nav-search-control {
  min-width: 423px !important;
  border-radius: 8px;
}

.nav-icon-bg {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
}

small,
.small {
  font-size: 0.775em;
}

// .modal-container{

// .modal-overlay {
//   z-index: 9999;
//   width: 100%;
//   height: 100vh;
//   position: absolute;
//   top: 0;
//   background-color: rgba(255, 255, 255, 0.116);
//   -webkit-backdrop-filter: blur(10px);
//   backdrop-filter: blur(10px);
//   display: flex;
//   justify-content: center;
//   align-items: center;


//   .modal-box {
//     display: block;
//     background: white;
//     width: 50%;
//     height: auto;
//     padding: 1rem;
//     // background-color: #6133FF;
//     box-shadow: 0px 0px 20px rgba(97, 51, 255, 0.5);
//   }
  
//   .modal-content{
//     padding:0% 10%;
//   }
  
//   .modal-submit-btn{
//     width:30%;
//   }
  
//   .modal-form-labels{
//     font-size:13px ;
//     font-weight: bold;
//   }
// }
// }

.container-modal{
  background-color: rgba(8, 176, 243, 0.116);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);

    .modal-body-style{
      width:auto;
    }
    
}

.modal-forms .form-group {
  position: relative;
  width: auto;
  margin-bottom: 1.5rem;

  .form-label {
    position: absolute;

    top: 0.5rem;

    left: 0.75rem;

    font-size: 12px;
    // margin-bottom: 2%;

    color: #6c757d;

    transition: all 0.2s ease-out;

    pointer-events: none;
  }

  // .form-control :focus + .form-label{
  //   top: -1rem;
  //   left: 0;
  //   font-size: 15px;
  //   color: #007bff;
  // }
  #inputField {
    padding-top: 8%;
  }
  #selectField {
    padding-top: 8%;
    width:100%;
  }
}

.tabs-style .nav-pills .nav-link.active,
.tabs-style .nav-pills .show > .nav-link {
  border-bottom: 5px solid #285f9f;
  background: unset;
  border-radius: unset;


  // border-image: linear-gradient(
  //   to bottom,
  //   rgba(108, 219, 141, 0) 25%,
  //   #32297d 25%,
  //   #285F9F 75%,
  //   rgba(108, 219, 141, 0) 75%
  // );
}
